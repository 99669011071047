import styled from 'styled-components';
import { ContainerColumn } from '../../styles/Common';
import { deviceMax } from '../../styles/MediaQuery';
import { black, blue, greyBorder } from '../../styles/Variables';

export const Container = styled(ContainerColumn)`
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    margin-top: 3.5rem;

    @media ${deviceMax.tabletM} {
        padding: 0.5rem;
    }
`;

export const AccordionContainer = styled.div`
    width: 100%;
`;

export const Title = styled.h1`
    color: ${blue};
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 0.025em;
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, sans-serif;
`;

export const Description = styled.div`
    * {
        font-size: 0.875rem;
        line-height: 20px;
        color: ${black};
    }
`;

export const PotentialApplicationsTitle = styled.h3`
    font-size: 0.94rem;
    text-transform: uppercase;
    line-height: 20px;
    margin: 0;
    letter-spacing: 0.025em;
    margin-top: 18px 0 11px 0;
    padding: 10px 0;
    border-bottom: 1px solid ${greyBorder};
    width: 100%;
    color: ${black};
    font-family: 'Helvetica Neue LT W02_57 Cond', Arial, Helvetica;
`;

export const ButtonWrapper = styled.div`
    margin: 2rem 0;
`;
